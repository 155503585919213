<template>
    <div class="flex -wrap columns news">
        <div v-for="item in articles" :key="item.id" :class="articleClass">
            <Highlight :title="item['title_' + lang]" :image="getFirstArticleImage(item)" :button="{
                title: $t('news.more_read'),
            }" :date="getArticleDate(item)" @buttonClick="onButtonClick(item)" />
        </div>
    </div>
</template>

<script>
import kebabcase from 'lodash.kebabcase';

export default {
    name: 'NewsArticles',
    components: {
        Highlight: () => import('@/components/Highlight.vue'),
    },
    props: {
        amount: {
            type: Number,
            default: 25,
        },
        articleClass: {
            type: String,
            default: 'col-12 col-6__s-up col-4__l-up mb-xl',
        },
        exclude: Number,
    },
    data() {
        return {
            articles: [],
        };
    },
    mounted() {
        let amount = this.amount;
        if (this.exclude) { amount += 1; }
        this.$store.dispatch('get', { page: 'items/news/', params: { count: amount, sort_by: 'id', sort_az: false } }).then((r) => {
            const articles = [];
            r.forEach((article) => {
                if (article.id !== this.exclude && articles.length !== this.amount) {
                    articles.push(article);
                }
            })
            this.articles = articles;
        });
    },
    methods: {
        kebabcase,

        getFirstArticleImage(article) {
            const config = article[`config_${this.lang}`];
            if (typeof config === 'object' && config.content) {
                const firstImageBlock = config.content.find((it) => it.type === 'image');
                if (firstImageBlock) {
                    return {
                        path: firstImageBlock.attrs.src,
                        ratio: '16by9',
                    };
                }
            }
            return false;
        },

        onButtonClick(item) {
            this.$router.push({
                name: 'newsitem',
                params: {
                    id: item.id,
                    slug: kebabcase(item[`title_${this.lang}`]),
                },
            });
        },

        getArticleDate(item) {
            const timestamp = item.edited?.timestamp || item.created.timestamp;
            const date = new Date(timestamp * 1000);
            return `${date.getDate()}-${date.getMonth() + 1}`;
        },
    },
    computed: {
        lang() {
            return this.$store.getters['Language/current'];
        },
    },
};
</script>
